import { Modal } from 'antd';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';
import Role from 'App/types/role';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UserFormValues } from '../../userForm/models/userFormValues';
import UserForm from '../../userForm/UserForm';

interface CreateUserModalProps {
	visible: boolean;
	loading: boolean;
	onCreate: (values: UserFormValues) => void;
	onCancel: () => void;
}

const CreateUserModal = ({ visible, loading, onCancel, onCreate }: CreateUserModalProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });
	const { t } = useTranslation();

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			destroyOnClose
			footer={null}
			closable
			title={t('page:AdminPages.UsersPages.CreateUserModalTitle')}
			width={isMobile ? '100%' : 800}
		>
			<UserForm
				loading={loading}
				onFinish={onCreate}
				initialValues={{
					isDeleted: false,
					emailConfirmed: false,
					phoneNumberConfirmed: false,
					lockoutEnabled: false,
					roles: [Role.User],
					language: 'pl',
					email: '',
					firstName: '',
					lastName: '',
					phoneNumber: '',
					id: undefined,
					lockoutEndUtc: '',
					password: '',
				}}
			/>
		</Modal>
	);
};

export default CreateUserModal;
