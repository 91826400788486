import moment, { Moment } from 'moment';

export const dateTimeUtils = {
	formats: {
		YearMonthDayHourMinute: 'YYYY-MM-DD HH:mm',
		YearMonthDayHourMinuteSecond: 'YYYY-MM-DD HH:mm:ss',
		HourMinute: 'HH:mm',
		HourMinuteSecond: 'HH:mm:ss',
	},

	getLocalDateTimeString: (date: string): string => {
		return moment(new Date(date + 'Z'))
			.local()
			.format(dateTimeUtils.formats.YearMonthDayHourMinuteSecond);
	},

	getLocalDateTimeFromDateTimeOffsetString: (date: string): string => {
		return moment(new Date(date)).local().format(dateTimeUtils.formats.YearMonthDayHourMinuteSecond);
	},

	getLocalDateTime: (date: string): Moment => {
		return moment(new Date(date + 'Z')).local();
	},
};
