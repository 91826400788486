import {
	getUsersStart,
	getUsersSuccess,
	getUserStart,
	getUserSuccess,
	getUserFailure,
	deleteUserStart,
	deleteUserSuccess,
	deleteUserFailure,
	createUserStart,
	createUserSuccess,
	createUserFailure,
	updateUserStart,
	updateUserSuccess,
	updateUserFailure,
	getUsersFailure,
	setUserPasswordFailure,
	setUserPasswordStart,
	setUserPasswordSuccess,
} from './users.slice';
import {
	GetUsersRequest,
	CreateUserRequest,
	UpdateUserRequest,
	SetUserPasswordRequest,
} from 'App/api/endpoints/users/requests';
import agent from 'App/api/agent/agent';
import { AppThunk } from 'App/globalState/store';

export const getUsers =
	(params: GetUsersRequest): AppThunk =>
	async (dispatch) => {
		dispatch(getUsersStart());
		agent.Users.getUsers(params)
			.then((response) => dispatch(getUsersSuccess(response)))
			.catch(() => dispatch(getUsersFailure()));
	};

export const getUser =
	(userId: string, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(getUserStart());
		agent.Users.getUser(userId)
			.then((response) => {
				dispatch(getUserSuccess(response));
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(() => dispatch(getUserFailure()));
	};

export const deleteUser =
	(userId: string, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(deleteUserStart());
		agent.Users.deleteUser(userId)
			.then(() => {
				dispatch(deleteUserSuccess(userId));
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(() => dispatch(deleteUserFailure()));
	};

export const createUser =
	(userToCreate: CreateUserRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(createUserStart());
		agent.Users.createUser(userToCreate)
			.then(() => {
				dispatch(createUserSuccess());
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(() => dispatch(createUserFailure()));
	};

export const updateUser =
	(userId: string, userToUpdate: UpdateUserRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(updateUserStart());
		agent.Users.updateUser(userId, userToUpdate)
			.then((res) => {
				dispatch(updateUserSuccess(res));
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(() => dispatch(updateUserFailure()));
	};

export const setUserPassword =
	(userId: string, request: SetUserPasswordRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(setUserPasswordStart());
		agent.Users.setUserPassword(userId, request)
			.then(() => {
				if (onSuccess) onSuccess();
				dispatch(setUserPasswordSuccess());
			})
			.catch(() => dispatch(setUserPasswordFailure()));
	};
