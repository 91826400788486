import React from 'react';

import { Route, Switch } from 'react-router';
import AllLogsContainer from './views/allLogs/AllLogsContainer';

const AdminLogsPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/logs' component={AllLogsContainer} />
		</Switch>
	);
};

export default AdminLogsPages;
