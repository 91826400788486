import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import { AccountApi } from '../endpoints/account/accountApi';
import { UsersApi } from '../endpoints/users/usersApi';
import { LogsApi } from '../endpoints/logs/logsApi';
import { AuthApi } from '../endpoints/auth/authApi';
import { baseURL } from './axios/configuration';
import { MaintenanceMessagesApi } from '../endpoints/maintenanceMessages/maintenanceMessagesApi';

const responseBodyAxios = (response: AxiosResponse) => {
	return response.data;
};

const responseBodyFetch = (response: Response) => response.json();

const defaultHeaders = {
	Accept: 'application/json, text/plain, */*',
	'Content-Type': 'application/json;charset=utf-8',
};

export const requests = {
	get: (url: string, params?: {}) =>
		axios
			.get(url, {
				params,
			})
			.then(responseBodyAxios),
	post: (url: string, body: {}, config?: AxiosRequestConfig | undefined) =>
		axios.post(url, body, config).then(responseBodyAxios),
	put: (url: string, body: {}, config?: AxiosRequestConfig | undefined) =>
		axios.put(url, body, config).then(responseBodyAxios),
	delete: (url: string) => axios.delete(url).then(responseBodyAxios),
	fetch: (url: string, config?: RequestInit) =>
		fetch(`${baseURL}${url}`, { ...config, headers: config.headers || defaultHeaders }).then((response) => {
			if (response && response.status !== 200) throw response;
			return responseBodyFetch(response);
		}),
	download: (url: string, fileName: string) =>
		axios({
			url: url,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}),
};

const agent = {
	Account: AccountApi,
	Auth: AuthApi,
	Logs: LogsApi,
	Users: UsersApi,
	MaintenanceMessages: MaintenanceMessagesApi,
};

export default agent;
