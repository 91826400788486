import { combineReducers } from '@reduxjs/toolkit';
import accountSlice from '../pages/accountPages/state/account.slice';

import adminLogsSlice from '../pages/adminPages/logsPages/state/logs.slice';
import adminMaintenanceMessagesSlice from '../pages/adminPages/maintenanceMessagesPages/state/maintenanceMessages.slice';
import adminUsersSlice from '../pages/adminPages/usersPages/state/users.slice';
import globalAccountSlice from './account/account.global.slice';

import globalAuthSlice from './auth/auth.global.slice';
import globalMaintenanceMessagesSlice from './maintenanceMessages/maintenanceMessages.global.slice';

const rootReducer = combineReducers({
	global: combineReducers({
		auth: globalAuthSlice.reducer,
		account: globalAccountSlice.reducer,
		maintenanceMessages: globalMaintenanceMessagesSlice.reducer,
	}),
	pages: combineReducers({
		admin: combineReducers({
			users: adminUsersSlice.reducer,
			logs: adminLogsSlice.reducer,
			messages: adminMaintenanceMessagesSlice.reducer,
		}),
		account: accountSlice.reducer,
	}),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
