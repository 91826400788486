import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageFormValues } from '../../forms/utils/messageFormValues';
import MessageForm from '../../forms/MessageForm';

export interface CreateMessageModalModalProps {
	showModal: boolean;
	initialValues: MessageFormValues;
	loading: boolean;
	onOk: (values: MessageFormValues) => void;
	onCancel: Function;
}

const UpdateMessageModal = ({ onCancel, onOk, showModal, loading, initialValues }: CreateMessageModalModalProps) => {
	const { t } = useTranslation();

	if (!initialValues) return <></>;

	return (
		<Modal
			title={t('AdminPages.MaintenanceMessagesPages.UpdateMessageModalTitle')}
			visible={showModal}
			footer={null}
			onCancel={() => onCancel()}
			maskClosable={false}
			destroyOnClose
		>
			<MessageForm loading={loading} onFinish={onOk} initialValues={initialValues} />
		</Modal>
	);
};

export default UpdateMessageModal;
