import { SettingOutlined, TranslationOutlined, CheckOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import { logout } from 'App/globalState/auth/auth.global.thunk';
import i18n, { languages, fullLanguages } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

interface AccountSubMenuProps {
	accountDetails: GetAccountDetailsResponse;
	isMobile: boolean;
}

const AccountSubMenu = (props: AccountSubMenuProps) => {
	const history = useHistory();
	const { t } = useTranslation('page');
	const dispatch = useDispatch();

	const onLogoutClick = () => {
		let handleSuccess: () => void = () => {
			history.push('/');
		};
		dispatch(logout(handleSuccess));
	};

	const changeLanguage = (values: any) => {
		i18n.changeLanguage(values.key);
	};

	const { accountDetails, isMobile, ...rest } = props;

	const className = isMobile ? '' : 'float-right';
	return (
		<Menu.SubMenu
			{...rest}
			key='/account'
			className={className}
			title={`${accountDetails?.firstName} ${accountDetails?.lastName}`}
		>
			<Menu.Item key='/account/edit-profile' icon={<SettingOutlined />}>
				<Link to='/account/edit-profile'>{t('Navbar.Settings')}</Link>
			</Menu.Item>
			<Menu.SubMenu key='languages' title={t('Navbar.Language')} icon={<TranslationOutlined />}>
				{languages.map((language) => (
					<Menu.Item
						key={language}
						onClick={changeLanguage}
						icon={i18n.language === language && <CheckOutlined />}
					>
						{fullLanguages.find((lang) => lang.key === language).value}
					</Menu.Item>
				))}
			</Menu.SubMenu>
			<Menu.Divider />

			<Menu.Item key='logout' onClick={onLogoutClick} icon={<LogoutOutlined />}>
				{t('Navbar.Logout')}
			</Menu.Item>
		</Menu.SubMenu>
	);
};

export default AccountSubMenu;
