import { Row, Col, Typography } from 'antd';
import { GetUserResponse } from 'App/api/endpoints/users/responses';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UserSecurityTabProps {
	user: GetUserResponse;
}

const UserSecurityTab = ({ user }: UserSecurityTabProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);

	if (user) {
		return (
			<>
				<Row gutter={[16, 16]}>
					{user.lockoutEnabled && (
						<Col span={24}>
							<Typography>
								<Typography.Text type='secondary'>
									{t('models:User.Labels.LockoutEnd')}:{' '}
								</Typography.Text>
								<Typography.Text>
									{user.lockoutEndUtc
										? dateTimeUtils.getLocalDateTimeFromDateTimeOffsetString(user.lockoutEndUtc)
										: '---'}
								</Typography.Text>
							</Typography>
						</Col>
					)}
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:User.Labels.AccessFailedCount')}:{' '}
							</Typography.Text>
							<Typography.Text>{user.accessFailedCount}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>{t('models:User.Labels.TwoFactor')}: </Typography.Text>
							<Typography.Text>
								{user.twoFactorEnabled
									? t('AdminPages.UsersPages.Active')
									: t('AdminPages.UsersPages.Inactive')}
							</Typography.Text>
						</Typography>
					</Col>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default UserSecurityTab;
