import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { StatusType } from 'App/types/requestStatus';
import { cleanUpSelectedUser, cleanUpUsersTable } from 'App/pages/adminPages/usersPages/state/users.slice';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { createUser, getUser, updateUser } from '../../state/users.thunk';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import UsersTable from './components/usersTable/UsersTable';
import CreateUserModal from '../createUser/modals/CreateUserModal';
import { UserFormValues } from '../userForm/models/userFormValues';
import moment from 'moment';
import UpdateUserModal from '../updateUser/modals/UpdateUserModal';
import { CreateUserRequest, UpdateUserRequest } from 'App/api/endpoints/users/requests';
import { deleteUser, getUsers, setUserPassword } from '../../state/users.thunk';
import SetUserPasswordModal from '../setUserPassword/modals/setUserPasswordModal/SetUserPasswordModal';
import { SetUserPasswordRequest } from 'App/api/endpoints/users/requests';

const { Loading } = StatusType;
const { defaultPaginationValues } = paginationUtils;
const UsersTableContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const users = useSelector((state: RootState) => state.pages.admin.users.users);
	const usersStatus = useSelector((state: RootState) => state.pages.admin.users.status);
	const getUserParams = useSelector((state: RootState) => state.pages.admin.users.getUsersParams);
	const selectedUser = useSelector((state: RootState) => state.pages.admin.users.selectedUser);
	const setUserPasswordStatus = useSelector((state: RootState) => state.pages.admin.users.status.setUserPassword);

	const [userFormModalVisible, setUserFormModalVisible] = useState(false);
	const [userSetPasswordModalVisiblity, setUserSetPasswordModalVisiblity] = useState<boolean>(false);
	const [userForSetPassword, setUserForSetPassword] = useState<UserForGetUsersResponse | null>(null);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		dispatch(
			getUsers({
				...defaultPaginationValues,
				filters: [{ field: 'isDeleted', values: ['false'] }],
				orderBy: [
					{
						field: 'lastName',
						direction: 'Ascending',
					},
				],
			})
		);

		return () => {
			dispatch(cleanUpUsersTable());
		};
	}, [dispatch]);

	const onDeleteUser = (userToDelete: UserForGetUsersResponse) => {
		Modal.confirm({
			title: `${t('AdminPages.UsersPages.ConfirmUserDeletionTitle')} ${userToDelete?.firstName} ${
				userToDelete?.lastName
			}?`,
			icon: <ExclamationCircleOutlined />,
			content: t('common:Warnings.ActionWillBeIrreversible'),
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.UsersPages.SuccessDeleteDescription'),
					});
				};
				dispatch(deleteUser(userToDelete.id, onSucces));
			},
		});
	};

	const showUserFormModal = (userId?: string) => {
		if (userId) {
			const onSuccess = () => {
				setUserFormModalVisible(true);
			};

			dispatch(getUser(userId, onSuccess));
		} else {
			setUserFormModalVisible(true);
		}
	};

	const hideUserFormModal = () => {
		setUserFormModalVisible(false);
		dispatch(cleanUpSelectedUser());
	};

	const onCreateUser = (values: UserFormValues) => {
		const request: CreateUserRequest = {
			...values,
			password: values.password,
			language: values.language,
			lockoutEndUtc: moment.utc(values.lockoutEndUtc)?.format(),
		};

		const onSucces = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.UsersPages.SuccessCreateDescription'),
			});
			dispatch(
				getUsers({
					...getUserParams,
				})
			);
			hideUserFormModal();
		};
		dispatch(createUser(request, onSucces));
	};

	const onUpdateUser = (values: UserFormValues) => {
		const request: UpdateUserRequest = {
			...values,
		};

		const onSucces = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.UsersPages.SuccessUpdateDescription'),
			});
			dispatch(
				getUsers({
					...getUserParams,
				})
			);
			hideUserFormModal();
		};
		dispatch(updateUser(selectedUser.id, request, onSucces));
	};

	const onSetUserPassword = (userToChangePassword: UserForGetUsersResponse) => {
		setUserSetPasswordModalVisiblity(true);
		setUserForSetPassword(userToChangePassword);
	};

	const handleSetUserPasswordModalCancel = () => {
		setUserSetPasswordModalVisiblity(false);
		setUserForSetPassword(null);
	};

	const handleSetUserPasswordModalSave = (values: SetUserPasswordRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.UsersPages.SuccessSetPasswordDescription'),
			});

			setUserSetPasswordModalVisiblity(false);
			setUserForSetPassword(null);
		};
		dispatch(setUserPassword(userForSetPassword.id, values, onSuccess));
	};

	return (
		<>
			<SetUserPasswordModal
				loading={setUserPasswordStatus === Loading}
				onCancel={() => handleSetUserPasswordModalCancel()}
				onSave={(values: SetUserPasswordRequest) => handleSetUserPasswordModalSave(values)}
				showModal={userSetPasswordModalVisiblity}
			/>
			<CreateUserModal
				loading={usersStatus.createUser === Loading}
				visible={!selectedUser && userFormModalVisible}
				onCancel={hideUserFormModal}
				onCreate={onCreateUser}
			/>
			<UpdateUserModal
				loading={usersStatus.updateUser === Loading}
				visible={selectedUser && userFormModalVisible}
				selectedUser={selectedUser}
				onCancel={hideUserFormModal}
				onUpdate={onUpdateUser}
			/>
			<Row>
				<Col span={24}>
					<Row>
						<Col xs={24}>
							<UsersTable
								dataSource={users}
								loading={usersStatus.getUsers === Loading}
								requestPagination={getUserParams}
								deleteUser={onDeleteUser}
								getUsers={getUsers}
								showUserFormModal={showUserFormModal}
								setUserPassword={onSetUserPassword}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default UsersTableContainer;
