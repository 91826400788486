import ProtectedRoute from 'App/common/components/ProtectedRoute';
import Role from 'App/types/role';
import React from 'react';
import AdminLayoutContainer from './adminLayout/AdminLayoutContainer';

const AdminPages = [
	<ProtectedRoute key='/admin' acceptedRoles={[Role.Admin]} path='/admin' component={AdminLayoutContainer} />,
];

export default AdminPages;
