import React from 'react';
import { Card, Row } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './styles/AllLogsCard.less';

interface AllLogsCardProps {
	onDownloadClick: () => void;
}

const AllLogsCard = ({ onDownloadClick }: AllLogsCardProps) => {
	const { t } = useTranslation('page');

	return (
		<Card className='all-logs-card' bordered={true} title={t('AdminPages.LogsPages.CardTitle')}>
			<Row justify='center' align='middle'>
				<DownloadOutlined className='all-logs-card__download-icon' onClick={onDownloadClick} />
			</Row>
		</Card>
	);
};

export default AllLogsCard;
