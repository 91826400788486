import { SettingFilled } from '@ant-design/icons';
import { Tag, Dropdown, Button, Menu, Table, TablePaginationConfig, Row, Col } from 'antd';
import Column from 'antd/lib/table/Column';
import { GetUsersRequest } from 'App/api/endpoints/users/requests';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import Role from 'App/types/role';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import UsersSearchForm from './forms/UsersSearchForm';
import { UsersSearchFormValues } from './forms/utils/usersSearchFormValues';

interface UsersTableProps {
	dataSource: UserForGetUsersResponse[];
	loading: boolean;
	requestPagination: IPagination;
	showUserFormModal: (userId?: string) => void;
	deleteUser: (userToDelete: UserForGetUsersResponse) => void;
	getUsers: (params: GetUsersRequest) => AppThunk;
	setUserPassword: (userToChangePassword: UserForGetUsersResponse) => void;
}

const { manageTableFilters, manageTableOrderBy, requestFiltersToAntdFilters, requestOrderByToAntdOrderBy } = tableUtils;

const UsersTable = ({
	dataSource,
	loading,
	requestPagination,
	deleteUser,
	getUsers,
	showUserFormModal,
	setUserPassword,
}: UsersTableProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { calculatePageNumber, handleOrderByChange, handleFiltersChange, defaultPaginationValues } = paginationUtils;
	const { pageNumber, pageSize, filters, orderBy, query, totalItems } = requestPagination;

	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);
	const translatedFilters = requestFiltersToAntdFilters(requestPagination.filters);

	const paginationConfig: TablePaginationConfig = {
		current: pageNumber,
		pageSize: pageSize,
		total: totalItems,
		showSizeChanger: true,
	};

	const onTableChange = (pagination, filters, sorter, extra): any => {
		let pageNumberToSet = calculatePageNumber(pagination, extra);

		const newOrderBy = handleOrderByChange(sorter);
		const newFilters = handleFiltersChange(filters);

		dispatch(
			getUsers({
				...requestPagination,
				...defaultPaginationValues,
				pageNumber: pageNumberToSet,
				pageSize: pagination.pageSize || defaultPaginationValues.pageSize,
				query: query,
				orderBy: newOrderBy,
				filters: newFilters,
			})
		);
	};

	const handleSearch = (values: UsersSearchFormValues) => {
		dispatch(
			getUsers({
				...requestPagination,
				...defaultPaginationValues,
				query: values.query,
				orderBy: orderBy,
				filters: filters,
				pageSize: pageSize,
			})
		);
	};

	const menuForActionDropdown = (record: UserForGetUsersResponse) => (
		<Menu>
			<Menu.Item key='edit-user'>
				<Button type='link' onClick={() => showUserFormModal(record.id)}>
					{t('common:Actions.Edit')}
				</Button>
			</Menu.Item>
			<Menu.Item key='set-user-password'>
				<Button type='link' onClick={() => setUserPassword(record)}>
					{t('common:Actions.SetPassword')}
				</Button>
			</Menu.Item>
			<Menu.Item key='delete-user'>
				<Button type='link' onClick={() => deleteUser(record)}>
					{t('common:Actions.Remove')}
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<Row>
			<Col className='mb-2' xs={24}>
				<UsersSearchForm handleSearch={handleSearch} loading={loading} showUserFormModal={showUserFormModal} />
			</Col>

			<Col xs={24}>
				<Table
					tableLayout='auto'
					rowKey='id'
					scroll={{ x: 'fit-content' }}
					pagination={paginationConfig}
					onChange={onTableChange}
					loading={loading}
					showHeader={!loading}
					dataSource={dataSource}
				>
					<Column
						key='firstName'
						dataIndex='firstName'
						title={t('models:User.Labels.FirstName')}
						sorter={{ multiple: 3 }}
						render={(firstName, record: UserForGetUsersResponse) => (
							<Link to={`/admin/users/${record.id}`}>{firstName}</Link>
						)}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'firstName')}
					/>
					<Column
						key='lastName'
						dataIndex='lastName'
						title={t('models:User.Labels.LastName')}
						sorter={{ multiple: 2 }}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'lastName')}
					/>
					<Column
						key='email'
						dataIndex='email'
						title={t('models:User.Labels.Email')}
						sorter={{ multiple: 1 }}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'email')}
					/>
					<Column
						title={t('models:User.Labels.EmailConfirmed')}
						dataIndex='emailConfirmed'
						render={(emailConfirmed: boolean) => (
							<>{emailConfirmed ? t('common:Actions.Yes') : t('common:Actions.No')}</>
						)}
						filters={[
							{ text: t('common:Actions.Yes'), value: true },
							{ text: t('common:Actions.No'), value: false },
						]}
						filterMultiple={false}
						filteredValue={manageTableFilters(translatedFilters, 'emailConfirmed')}
					/>
					<Column
						title={t('models:User.Labels.IsDeleted')}
						dataIndex='isDeleted'
						render={(isDeleted: boolean) => (
							<>{isDeleted ? t('common:Actions.Yes') : t('common:Actions.No')}</>
						)}
						filters={[
							{ text: t('common:Actions.Yes'), value: true },
							{ text: t('common:Actions.No'), value: false },
						]}
						filterMultiple={false}
						filteredValue={manageTableFilters(translatedFilters, 'isDeleted')}
					/>
					<Column
						title={t('models:User.Labels.Roles')}
						dataIndex='roles'
						render={(roles: string[]) => (
							<>
								{roles.map((role: string) => {
									const roleTranslationKey = `common:Roles.${role}`;
									const color = role === Role.Admin ? 'blue' : 'volcano';
									return (
										<Tag key={role} color={color}>
											{t(roleTranslationKey)}
										</Tag>
									);
								})}
							</>
						)}
					/>

					<Column
						title={t('common:Actions.Actions')}
						render={(record: UserForGetUsersResponse) => (
							<Dropdown
								overlay={() => menuForActionDropdown(record)}
								trigger={['click']}
								placement='bottomCenter'
							>
								<Button type='link'>
									<SettingFilled />
								</Button>
							</Dropdown>
						)}
					/>
				</Table>
			</Col>
		</Row>
	);
};

export default UsersTable;
