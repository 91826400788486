import { UserAddOutlined, LoginOutlined, TranslationOutlined, CheckOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import i18n, { languages, fullLanguages } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import HomeMenuItem from './HomeMenuItem';

const NotLoggedInNavbar = () => {
	const { t } = useTranslation('page');
	const location = useLocation();
	const changeLanguage = (values: any) => {
		i18n.changeLanguage(values.key);
	};

	const aboutUsMenuItem = (isMobile: boolean) => (
		<Menu.Item className={isMobile ? '' : ''}>
			O nas
		</Menu.Item>
	);

	const portfolioMenuItem = (isMobile: boolean) => (
		<Menu.Item className={isMobile ? '' : ''}>
			Portfolio
		</Menu.Item>
	);

	const offerMenuItem = (isMobile: boolean) => (
		<Menu.Item className={isMobile ? '' : ''}>
			Oferta
		</Menu.Item>
	);

	const contactMenuItem = (isMobile: boolean) => (
		<Menu.Item className={isMobile ? '' : ''}>
			Kontakt
		</Menu.Item>
	);


	const signUpMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-up' icon={<UserAddOutlined />} className={isMobile ? '' : ''}>
			<Link to='/sign-up'>{t('Navbar.SignUp')}</Link>
		</Menu.Item>
	);

	const signInMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-in' icon={<LoginOutlined />} className={isMobile ? '' : ''}>
			<Link to='/sign-in'>{t('Navbar.SignIn')}</Link>
		</Menu.Item>
	);

	const languagesMenuItem = (isMobile: boolean) => (
		<Menu.SubMenu
			key='languages'
			title={isMobile ? t('Navbar.Language') : ''}
			icon={<TranslationOutlined className={isMobile ? '' : 'mr-0'} />}
			className={isMobile ? '' : ''}
		>
			{languages.map((language) => (
				<Menu.Item
					key={language}
					onClick={changeLanguage}
					icon={i18n.language === language && <CheckOutlined />}
				>
					{fullLanguages.find((lang) => lang.key === language).value}
				</Menu.Item>
			))}
		</Menu.SubMenu>
	);

	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<Menu.SubMenu key='notlogged-mobile' icon={<MenuOutlined className='mr-0' />}>
								{aboutUsMenuItem(true)}
								{portfolioMenuItem(true)}
								{offerMenuItem(true)}
								{contactMenuItem(true)}

								{/* {signUpMenuItem(true)}
								{signInMenuItem(true)} */}
								{/* {languagesMenuItem(true)} */}
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>
			<Default>
				<Row justify='space-between'>
					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
						</Menu>
					</Col>

					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
								{aboutUsMenuItem(false)}
								{portfolioMenuItem(false)}
								{offerMenuItem(false)}
								{contactMenuItem(false)}


							{/* {signUpMenuItem(false)}
							{signInMenuItem(false)} */}
							{/* {languagesMenuItem(false)} */}
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default NotLoggedInNavbar;
