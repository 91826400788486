import { Alert, Col, Row } from 'antd';
import { getCurrentMessages } from 'App/globalState/maintenanceMessages/maintenanceMessages.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MaintenanceMessages = () => {
	const dispatch = useDispatch();

	const currentMessages = useSelector((state: RootState) => state.global.maintenanceMessages.currentMessages);

	useEffect(() => {
		dispatch(getCurrentMessages());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (currentMessages.length === 0) return <></>;

	return (
		<Row justify='center' className='mt-2'>
			<Col xs={24} md={12}>
				{currentMessages.map((message, index) => (
					<Alert key={index} message={message.description} className='mt-1' banner closable />
				))}
			</Col>
		</Row>
	);
};

export default MaintenanceMessages;
