import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from 'App/common/components/ProtectedRoute';

import Role from 'App/types/role';
import ConfirmEmailContainer from './views/confirmEmail/ConfirmEmailContainer';
import EditProfileContainer from './views/editProfile/EditProfileContainer';
import ForgotPasswordContainer from './views/forgotPassword/ForgotPasswordContainer';
import ResendConfirmationEmailContainer from './views/resendConfirmationEmail/ResendConfirmationEmailContainer';
import ResetPasswordContainer from './views/resetPassword/ResetPasswordContainer';

const AccountPages = [
	<Route key='/account/reset-password' exact path='/account/reset-password' component={ResetPasswordContainer} />,
	<Route
		key='/account/resend-confirmation-email'
		exact
		path='/account/resend-confirmation-email'
		component={ResendConfirmationEmailContainer}
	/>,
	<Route
		key='/account/confirm-email/:userId/:confirmationCode'
		path='/account/confirm-email/:userId/:confirmationCode'
		component={ConfirmEmailContainer}
	/>,
	<Route key='/account/forgot-password' exact path='/account/forgot-password' component={ForgotPasswordContainer} />,
	<Route
		key='/account/reset-password/:userId/:passwordResetCode'
		path='/account/reset-password/:userId/:passwordResetCode'
		component={ResetPasswordContainer}
	/>,
	<ProtectedRoute
		key='/account/edit-profile'
		path='/account/edit-profile'
		component={EditProfileContainer}
		acceptedRoles={[Role.Admin, Role.User]}
	/>,
];

export default AccountPages;
