import { GetUserResponse } from 'App/api/endpoints/users/responses';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { IPagination } from 'App/types/pagination/pagination';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;
const { defaultPaginationValues } = paginationUtils;

export interface AdminUsersState {
	status: {
		getUsers: StatusType;
		getUser: StatusType;
		deleteUser: StatusType;
		createUser: StatusType;
		updateUser: StatusType;
		setUserPassword: StatusType;
	};
	users: UserForGetUsersResponse[];
	getUsersParams: IPagination;
	selectedUser: GetUserResponse | null;
}

export const adminUsersInitialState: AdminUsersState = {
	status: {
		getUsers: Initial,
		getUser: Initial,
		deleteUser: Initial,
		createUser: Initial,
		updateUser: Initial,
		setUserPassword: Initial,
	},
	users: [],
	getUsersParams: defaultPaginationValues,
	selectedUser: null,
};
