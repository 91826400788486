import {
	getMessagesStart,
	getMessagesSuccess,
	getMessageStart,
	getMessageSuccess,
	getMessageFailure,
	deleteMessageStart,
	deleteMessageSuccess,
	deleteMessageFailure,
	createMessageStart,
	createMessageSuccess,
	createMessageFailure,
	updateMessageStart,
	updateMessageSuccess,
	updateMessageFailure,
	getMessagesFailure,
} from './maintenanceMessages.slice';
import {
	CreateMessageRequest,
	GetMessagesRequest,
	UpdateMessageRequest,
} from 'App/api/endpoints/maintenanceMessages/requests';
import agent from 'App/api/agent/agent';
import { IPagination } from 'App/types/pagination/pagination';
import { AppThunk } from 'App/globalState/store';

export const getAllMessages = (params: IPagination): AppThunk => async (dispatch) => {
	getMessages({ ...params, messagesType: 'All' });
};

export const getUpcomingMessages = (params: IPagination): AppThunk => async (dispatch) => {
	getMessages({ ...params, messagesType: 'Upcoming' });
};

export const getCurrentMessages = (params: IPagination): AppThunk => async (dispatch) => {
	getMessages({ ...params, messagesType: 'Current' });
};

export const getMessages = (params: GetMessagesRequest): AppThunk => async (dispatch) => {
	dispatch(getMessagesStart());
	agent.MaintenanceMessages.getMessages(params)
		.then((response) => dispatch(getMessagesSuccess(response)))
		.catch(() => dispatch(getMessagesFailure()));
};

export const getMessage = (messageId: number): AppThunk => async (dispatch) => {
	dispatch(getMessageStart());
	agent.MaintenanceMessages.getMessage(messageId)
		.then((response) => dispatch(getMessageSuccess(response)))
		.catch(() => dispatch(getMessageFailure()));
};

export const deleteMessage = (messageId: number, onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(deleteMessageStart());
	agent.MaintenanceMessages.deleteMessage(messageId)
		.then(() => {
			if (onSuccess) {
				onSuccess();
			}
			dispatch(deleteMessageSuccess(messageId));
		})
		.catch(() => dispatch(deleteMessageFailure()));
};

export const createMessage = (messageToCreate: CreateMessageRequest, onSuccess?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(createMessageStart());
	agent.MaintenanceMessages.createMessage(messageToCreate)
		.then(() => {
			dispatch(createMessageSuccess());
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(createMessageFailure()));
};

export const updateMessage = (
	messageId: number,
	messageToUpdate: UpdateMessageRequest,
	onSuccess?: () => void
): AppThunk => async (dispatch) => {
	dispatch(updateMessageStart());
	agent.MaintenanceMessages.updateMessage(messageId, messageToUpdate)
		.then((res) => {
			dispatch(updateMessageSuccess(res));
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => dispatch(updateMessageFailure()));
};
