import { Col, notification, PageHeader, Row } from 'antd';
import StatusType from 'App/types/requestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { ResendConfirmationEmailRequest } from 'App/api/endpoints/account/requests';
import ResendConfirmationEmailForm from './forms/ResendConfirmationEmailForm';
import { RootState } from 'App/globalState/root.reducer';
import { resendConfirmationEmail } from '../../state/account.thunk';

interface ResendConfirmationEmailContainerProps extends RouteComponentProps {}

const ResendConfirmationEmailContainer = ({ history }: ResendConfirmationEmailContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const dispatch = useDispatch();
	const resendConfirmationEmailStatus = useSelector(
		(state: RootState) => state.pages.account.status.resendConfirmationEmail
	);
	const { t } = useTranslation('page');

	const resendConfirmationEmailHandler: FinishFormType = (values: ResendConfirmationEmailRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/sign-in');
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.EmailWasResent'),
			});
		};

		dispatch(resendConfirmationEmail(values, handleSuccess));
	};

	return (
		<div>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<br />
					<PageHeader title={t('AccountPages.ResendEmailPageHeaderTitle')} />
					<ResendConfirmationEmailForm
						name='resendConfirmationEmailForm'
						size='large'
						onFinish={resendConfirmationEmailHandler}
						autoComplete='off'
						loading={resendConfirmationEmailStatus === StatusType.Loading}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default ResendConfirmationEmailContainer;
