export const breakpoints = {
	xxs: {
		min: 0,
		max: 319,
	},
	xs: {
		min: 320,
		max: 575,
	},
	sm: {
		min: 576,
		max: 767,
	},
	md: {
		min: 768,
		max: 991,
	},
	lg: {
		min: 992,
		max: 1199,
	},
	xl: {
		min: 1200,
		max: 1599,
	},
	xxl: {
		min: 1600,
		max: 1999,
	},
	xxxl: {
		min: 2000,
		max: 99999,
	},
};
