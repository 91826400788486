import { SettingFilled } from '@ant-design/icons';
import { Tooltip, Dropdown, Button, Menu, Table, TablePaginationConfig, Col, Row } from 'antd';
import Column from 'antd/lib/table/Column';
import { GetMessagesRequest } from 'App/api/endpoints/maintenanceMessages/requests';
import { MessageForGetMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MessagesSearchForm from '../forms/MessagesSearchForm';
import './MessagesTable.less';

interface MessagesTableProps {
	dataSource: MessageForGetMessagesResponse[];
	loading: boolean;
	requestPagination: GetMessagesRequest;
	onDeleteMessageClick: (messageToDelete: MessageForGetMessagesResponse) => void;
	getMessages: (params: GetMessagesRequest) => AppThunk;
	onUpdateMessageClick: (message: MessageForGetMessagesResponse) => void;
	setCreateMessageModalVisibility: (visible: boolean) => void;
	setMessagesType: (messageType: 'All' | 'Upcoming' | 'Current') => void;
}

const { manageTableOrderBy, requestOrderByToAntdOrderBy } = tableUtils;
const { getLocalDateTimeString } = dateTimeUtils;

const MessagesTable = ({
	dataSource,
	loading,
	requestPagination,
	onDeleteMessageClick,
	getMessages,
	onUpdateMessageClick,
	setCreateMessageModalVisibility,
	setMessagesType,
}: MessagesTableProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { calculatePageNumber, handleOrderByChange, handleFiltersChange, defaultPaginationValues } = paginationUtils;
	const { pageNumber, filters, orderBy, pageSize, query, totalItems } = requestPagination;
	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);

	const paginationConfig: TablePaginationConfig = {
		current: pageNumber,
		pageSize: pageSize,
		total: totalItems,
		showSizeChanger: true,
	};

	const handleSearch = (values: MessagesSearchFormValues) => {
		dispatch(
			getMessages({
				...requestPagination,
				...defaultPaginationValues,
				query: values.query,
				orderBy: orderBy,
				filters: filters,
				pageSize: pageSize,
			})
		);
	};

	const handleTableChange = (pagination, filters, sorter, extra): any => {
		let pageNumberToSet = calculatePageNumber(pagination, extra);

		const newOrderBy = handleOrderByChange(sorter);
		const newFilters = handleFiltersChange(filters);

		dispatch(
			getMessages({
				...requestPagination,
				...defaultPaginationValues,
				pageNumber: pageNumberToSet,
				pageSize: pagination.pageSize || defaultPaginationValues.pageSize,
				query: query,
				orderBy: newOrderBy,
				filters: newFilters,
			})
		);
	};

	const menuForActionDropdown = (record: MessageForGetMessagesResponse) => (
		<Menu>
			<Menu.Item key='edit-message'>
				<Button type='link' onClick={() => onUpdateMessageClick(record)}>
					{t('common:Actions.Edit')}
				</Button>
			</Menu.Item>
			<Menu.Item key='delete-message'>
				<Button type='link' onClick={() => onDeleteMessageClick(record)}>
					{t('common:Actions.Remove')}
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<Row>
			<Col xs={24}>
				<MessagesSearchForm
					handleSearch={handleSearch}
					loading={loading}
					setCreateMessageModalVisibility={setCreateMessageModalVisibility}
					setMessagesType={setMessagesType}
				/>
			</Col>

			<Col xs={24}>
				<Table
					onChange={handleTableChange}
					pagination={paginationConfig}
					loading={loading}
					showHeader={!loading}
					dataSource={dataSource}
					tableLayout='auto'
					rowKey='id'
					scroll={{ x: 'fit-content' }}
				>
					<Column
						key='startDateInUtc'
						dataIndex='startDateInUtc'
						title={t('models:MaintenanceMessage.Labels.StartDate')}
						sorter={{
							multiple: 3,
						}}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'startDateInUtc')}
						render={(startDate: string) => <>{getLocalDateTimeString(startDate)}</>}
					/>
					<Column
						key='endDateInUtc'
						dataIndex='endDateInUtc'
						title={t('models:MaintenanceMessage.Labels.EndDate')}
						sorter={{
							multiple: 2,
						}}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'endDateInUtc')}
						render={(endDate: string) => <>{getLocalDateTimeString(endDate)}</>}
					/>
					<Column
						key='description'
						dataIndex='description'
						title={t('models:MaintenanceMessage.Labels.Description')}
						sorter={{
							multiple: 1,
						}}
						sortOrder={manageTableOrderBy(translatedOrderBy, 'description')}
						width='30%'
						className='messages-table__description-column'
						render={(description) => (
							<Tooltip title={description}>
								<div className='messages-table__description-tooltip'>{description}</div>
							</Tooltip>
						)}
					/>
					<Column
						title={t('common:Actions.Actions')}
						render={(record: MessageForGetMessagesResponse) => (
							<Dropdown
								overlay={menuForActionDropdown(record)}
								trigger={['click']}
								placement='bottomCenter'
							>
								<Button type='link'>
									<SettingFilled />
								</Button>
							</Dropdown>
						)}
					/>
				</Table>
			</Col>
		</Row>
	);
};

export default MessagesTable;
