import React from 'react';

import AccountPages from './pages/accountPages/AccountPages';
import HomePages from './pages/homePages/HomePages';
import ResultPages from './pages/resultPages/ResultPages';
import AdminPages from './pages/adminPages/AdminPages';
import AuthPages from './pages/authPages/AuthPages';
import { Switch } from 'react-router-dom';

const Routes = () => {
	return (
		<Switch>
			{HomePages}
			{AuthPages}
			{AdminPages}
			{AccountPages}

			{ResultPages}
		</Switch>
	);
};

export default Routes;
