import appConfig from 'app.config';
import { requests } from '../../agent/agent';
import { LoginRequest, RegisterRequest } from './requests';
import { LoginResponse, RegisterResponse, RefreshTokenResponse } from './responses';

const { baseUrlToIncludeInEmail, confirmEmail } = appConfig.urls.frontend;

export const AuthApi = {
	login: (body: LoginRequest): Promise<LoginResponse> =>
		requests.fetch(`auth/login`, {
			body: JSON.stringify(body),
			method: 'post',
			credentials: 'include',
		}),

	logout: (): Promise<any> => requests.post(`/auth/logout`, {}),

	register: (body: RegisterRequest): Promise<RegisterResponse> =>
		requests.post(`/auth/register`, { ...body, urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${confirmEmail}` }),

	refreshToken: (): Promise<RefreshTokenResponse> => {
		return requests.fetch('auth/refresh-token', {
			method: 'get',
			credentials: 'include',
		});
	},
};
