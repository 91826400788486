import React from 'react';
import { Button, Col, Image, Input, Row } from 'antd';
import './HomeContainer.less';
import logo from '../../../../common/assets/logo.png';
import ReactPlayer from 'react-player';
import Carousel from 'react-elastic-carousel';

import portfolioImage1 from './assets/portfolio-image-1.jpeg';
import portfolioImage2 from './assets/portfolio-image-2.jpeg';
import portfolioImage3 from './assets/portfolio-image-3.jpeg';
import portfolioImage4 from './assets/portfolio-image-4.jpeg';
import portfolioImage5 from './assets/portfolio-image-5.jpeg';
import portfolioImage6 from './assets/portfolio-image-6.jpeg';
import portfolioImage7 from './assets/portfolio-image-7.jpeg';
import portfolioImage8 from './assets/portfolio-image-8.jpeg';
import portfolioImage9 from './assets/portfolio-image-9.jpeg';
import portfolioImage10 from './assets/portfolio-image-10.jpeg';
import portfolioImage11 from './assets/portfolio-image-11.jpeg';

import galleryImage1 from './assets/gallery-image-1.jpeg';
import galleryImage2 from './assets/gallery-image-2.jpeg';
import galleryImage3 from './assets/gallery-image-3.jpeg';

import bigLogoImage from './assets/big-logo.png';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';


const HomeContainer = () => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });

	return (
		<>
			{/* Sekcja landing */}
			<Row className='home-container__landing-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row className='home-container__landing-section__row'>
						<Col xs={24} sm={24} md={10} lg={10} xl={10}>
							<Row  align='middle' className='home-container__landing-section__text-section'>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Row className='home-container__landing-section__title'>
										Podobno
									</Row>
									<Row className='home-container__landing-section__subtitle'>
										Mamy pomysł na Twoją markę.
									</Row>
									<Row className='home-container__landing-section__description'>
										Łączymy różne działania tak, żeby klienci nawiązali trwałą relację z Twoją marką.<br/>
										I Podobno nam to wychodzi.
									</Row>
									<Row>
										<div className='home-container__landing-section__divider'/>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={14} lg={14} xl={14}>
							<Row justify='end' align='middle' className='home-container__landing-section__video-section'>
								<ReactPlayer playing={true} loop={true} url='https://vimeo.com/457947497' />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Sekcja portfolio */}
			<Row className='home-container__portfolio-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row>
						<Col xs={24} sm={24} md={10} lg={10} xl={10}>
							<Row className='home-container__portfolio-section__title'>
								O NAS
							</Row>
							<Row className='home-container__portfolio-section__subtitle'>
								Nasze projekty
							</Row>
							<Row className='home-container__portfolio-section__description'>
								Podobno jesteśmy najlepsi, bo się uzupełniamy. <br/>
								Graficy, animatorzy, copywriterzy, fotograficy, programiści, stratedzy – każdy z nas specjalizuje się <br/>
								w jednej dziedzinie, ale razem potrafimy wszystko.
							</Row>
							<Row className='home-container__portfolio-section__button-section'>
								<Button className='home-container__portfolio-section__button-section__button'>
									Zobacz wszystkie
								</Button>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={14} lg={14} xl={14}>
							<Row justify='end'>
								<Carousel itemsToScroll={1} itemsToShow={isMobile ? 1 : 2} >

									{/* Inlei */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage1} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Inlei
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
											Zakres współpracy :
												<ul>
													<li>
														Budowa sklepu internetowego
													</li>
													<li>
														Promocja
													</li>
													<li>
														Sesje zdjęciowe
													</li>
												</ul>
											</Row>
										</Col>
									</div>
									
									{/* Restauracja Jasna */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage2} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Restauracja Jasna
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Budowa strony www
													</li>
													<li>
														Promocja w internecie
													</li>
													<li>
														Social media content
													</li>
												</ul>
											</Row>
										</Col>
									</div>
									
									{/* Warsztat Restauracja */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage3} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Warsztat Restauracja
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Budowa strony www
													</li>
													<li>
														Promocja
													</li>
													<li>
														Social media content
													</li>
													<li>
														Sesje zdjęciowe
													</li>
													<li>
														Autorskie oprogramowanie marketingowe
													</li>
												</ul>
											</Row>
										</Col>
									</div>
									
									{/* Gafas */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage4} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Gafas
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Video content
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Produkcja i realizacja wideo
													</li>
													<li>
														Social media content
													</li>
												</ul>
											</Row>
										</Col>
									</div>
									
									{/* Vantage Development */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage5} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Vantage Development
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Produkcja filmu promocyjnego
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Scenariusz
													</li>
													<li>
														Produkcja
													</li>
													<li>
														Montaż
													</li>
													<li>
														Social media content
													</li>
													<li>
														Autorskie oprogramowanie marketingowe
													</li>
												</ul>
											</Row>
										</Col>
									</div>

									{/* Aixam Wrocław */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage6} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Aixam Wrocław
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Budowa sklepu internetowego
													</li>
													<li>
														Promocja w internecie
													</li>
													<li>
														Sesje zdjęciowe
													</li>
													<li>
														Social media content
													</li>
												</ul>
											</Row>
										</Col>
									</div>

									{/* Twoje soczewki */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage7} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Twoje soczewki
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Sesja produktowa
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Sesja zdjęciowa produktowa
													</li>
													<li>
														Social media content
													</li>
												</ul>
											</Row>
										</Col>
									</div>

									{/* Restauracja Thali */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage9} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Restauracja Thali
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Content marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Budowa strony www
													</li>
													<li>
														Promocja
													</li>
													<li>
														Sesje zdjęciowe
													</li>
													<li>
														Social media content
													</li>
													<li>
														Budowa aplikacji mobilnej
													</li>
												</ul>
											</Row>
										</Col>
									</div>

									{/* Restauracja Naru */}
									<div>
										<Col className='home-container__portfolio-section__portfolio-element'>
											<Row>
												<img src={portfolioImage11} className='home-container__portfolio-section__portfolio-element__image' />
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__title'>
												Restauracja Naru
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__subtitle'>
												Marketing
											</Row>
											<Row className='home-container__portfolio-section__portfolio-element__description'>
												Zakres współpracy :
												<ul>
													<li>
														Budowa strony www
													</li>
													<li>
														Promocja
													</li>
													<li>
														Sesje zdjęciowe
													</li>
													<li>
														Social media content
													</li>
													<li>
														Budowa aplikacji mobilnej
													</li>
													<li>
														Autorskie oprogramowanie marketingowe
													</li>
												</ul>
											</Row>
										</Col>
									</div>
									

								</Carousel>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Sekcja z aktualnościami - tytuł i opis */}
			<Row className='home-container__news-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row className='home-container__news-section__title'>
						O NAS
					</Row>
					<Row className='home-container__news-section__subtitle'>
						Aktualności
					</Row>
					<Row className='home-container__news-section__description'>
						Co u nas słychać
					</Row>
				</Col>
			</Row>
			{/* Sekcja z aktualnościami - slider */}
			<Row className='home-container__news-section-carousel' justify='center'>
				<Carousel itemsToScroll={1} itemsToShow={1} >

					{/* Zrobiliśmy film */}
					<div>
						<Row justify='center'>
							<Col xs={24} sm={20} md={18} lg={18} xl={16}>
								<Row justify='center'>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__image-column' >
										<Row justify='end'>
											<img src={portfolioImage5} className='home-container__news-section-carousel__image-column__image'/>
										</Row>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__text-column'>
										<Row align='middle' className='home-container__news-section-carousel__text-column__row'>
											<Col>
												<Row className='home-container__news-section-carousel__text-column__row__date'>
													01.12.2021
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__title'>
													Zrobiliśmy film
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__description'>
													Zakończyliśmy prace nad filmem promującym inwestycję Port Popowice we Wrocławiu. Efekty oceńcie sami. Cieszymy się, że mogliśmy brać udział przy realizacji tak wymagającego projektu.
												</Row>
												<Row>
													<Button className='home-container__news-section-carousel__text-column__row__button'>
														Wiecej
													</Button>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					{/* Zapraszamy do warsztatu */}
					<div>
						<Row justify='center'>
							<Col xs={20} sm={20} md={18} lg={18} xl={16}>
								<Row justify='center'>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__image-column' >
										<Row justify='end'>
											<img src={portfolioImage3} className='home-container__news-section-carousel__image-column__image'/>
										</Row>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__text-column'>
										<Row align='middle' className='home-container__news-section-carousel__text-column__row'>
											<Col>
												<Row className='home-container__news-section-carousel__text-column__row__date'>
													01.12.2021
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__title'>
													Zapraszamy do warsztatu
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__description'>
													Okazuje się, że warsztat może służyć nie tylko mechanikom ale i kucharzom. Warsztat to oryginalna restauracja i wyjątkowa kuchnia. Nasz zespół odpowiadać będzie za content oraz promocję restauracji i sklepu.												</Row>
												<Row>
													<Button className='home-container__news-section-carousel__text-column__row__button'>
														Wiecej
													</Button>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					{/* Polska kuchnia - smak i przyjemność */}
					<div>
						<Row justify='center'>
							<Col xs={20} sm={20} md={18} lg={18} xl={16}>
								<Row justify='center'>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__image-column' >
										<Row justify='end'>
											<img src={portfolioImage2} className='home-container__news-section-carousel__image-column__image'/>
										</Row>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__text-column'>
										<Row align='middle' className='home-container__news-section-carousel__text-column__row'>
											<Col>
												<Row className='home-container__news-section-carousel__text-column__row__date'>
													01.12.2021
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__title'>
													Polska kuchnia - smak i przyjemność
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__description'>
													Restauracja Jasna w naszych rękach. Promocją oraz Social Media zajmie się agencja Podobno . Do naszych zadań będzie należała promocja restauracji w internecie oraz obsługa social media. Będziemy również odpowiedzialni za content graficzny oraz promocję outdoor.												</Row>
												<Row>
													<Button className='home-container__news-section-carousel__text-column__row__button'>
														Wiecej
													</Button>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					{/* Znamy się na sushi */}
					<div>
						<Row justify='center'>
							<Col xs={20} sm={20} md={18} lg={18} xl={16}>
								<Row justify='center'>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__image-column' >
										<Row justify='end'>
											<img src={portfolioImage11} className='home-container__news-section-carousel__image-column__image'/>
										</Row>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12} xl={12} className='home-container__news-section-carousel__text-column'>
										<Row align='middle' className='home-container__news-section-carousel__text-column__row'>
											<Col>
												<Row className='home-container__news-section-carousel__text-column__row__date'>
													01.12.2021
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__title'>
													Znamy się na sushi
												</Row>
												<Row className='home-container__news-section-carousel__text-column__row__description'>
													Naru – znaczy smacznie. Agencja Podobno zajmie się kompleksową promocją restauracji w internecie.												</Row>
												<Row>
													<Button className='home-container__news-section-carousel__text-column__row__button'>
														Wiecej
													</Button>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

				</Carousel>
			</Row>






			{/* Sekcja z ofertą - tytuł i opis */}
			<Row className='home-container__services-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row className='home-container__services-section__title'>
						USŁUGI
					</Row>
					<Row className='home-container__services-section__subtitle'>
						Oferta
					</Row>
					<Row className='home-container__services-section__description'>
						Nasze usługi są dostępne w pakietach abonamentowych<br/> skrojonych na miarę tak, żeby jak najlepiej<br/>
						dostosować się do potrzeb Twojej marki.<br/>
						Zobacz nasze przykładowe abonamenty i zobacz, ile zyskujesz.
					</Row>
				</Col>
			</Row>

			{/* Sekcja z ofertą - kafelki */}
			<Row className='home-container__services-tiles-section' justify='center'>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row justify='center'>
						<Col xs={24} sm={12} md={6} lg={6} xl={6} className='home-container__services-tiles-section__tile__1'>
							<Row className='home-container__services-tiles-section__tile__number'>
								01
							</Row>
							<Row className='home-container__services-tiles-section__tile__title'>
								Marketing
							</Row>
							<Row className='home-container__services-tiles-section__tile__description'>
								Wiemy, jak Twoja marka powinna komunikować się z klientami. Opracujemy strategię komunikacji i wdrożymy ją na wszystkich wybranych polach.
							</Row>
						</Col>
						<Col xs={24} sm={12} md={6} lg={6} xl={6} className='home-container__services-tiles-section__tile__2'>
							<Row className='home-container__services-tiles-section__tile__number'>
								02
							</Row>
							<Row className='home-container__services-tiles-section__tile__title'>
								Social media
							</Row>
							<Row className='home-container__services-tiles-section__tile__description'>
								Pokażemy „ludzką twarz” twojej marki i zbudujemy jej popularność. Posty, konkursy, komunikacja z obserwującymi – przekonasz się, jaką moc mają media społecznościowe!
							</Row>
						</Col>
						<Col xs={24} sm={12} md={6} lg={6} xl={6} className='home-container__services-tiles-section__tile__3'>
							<Row className='home-container__services-tiles-section__tile__number'>
								03
							</Row>
							<Row className='home-container__services-tiles-section__tile__title'>
								Web design + SEO
							</Row>
							<Row className='home-container__services-tiles-section__tile__description'>
								Zbudujemy Twojej marce jej własne, wygodne miejsce w Internecie. Stworzymy Twoją stronę internetową i sprawimy, że będzie przyjazna dla wyszukiwarek, tak żeby Twoja firma była zawsze dobrze widoczna.
							</Row>
						</Col>
						<Col xs={24} sm={12} md={6} lg={6} xl={6} className='home-container__services-tiles-section__tile__4'>
							<Row className='home-container__services-tiles-section__tile__number'>
								04
							</Row>
							<Row className='home-container__services-tiles-section__tile__title'>
								Design
							</Row>
							<Row className='home-container__services-tiles-section__tile__description'>
								Stworzymy dla Ciebie identyfikację wizualną, która będzie spójna ze strategią i innymi działaniami. Potrzebujesz nowe logo? A może cały System Identyfikacji Wizualnej? Zbudujemy dla Ciebie nowy, wyróżniający wizerunek.
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Sekcja ze zdjęciami */}
			<Row className='home-container__gallery-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row justify='center' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col xs={24} sm={20} md={8} lg={8} xl={8}>
							<Row>
								<img src={galleryImage1} className='home-container__gallery-section__image' />
							</Row>
						</Col>
						<Col xs={24} sm={20} md={8} lg={8} xl={8}>
							<Row>
								<img src={galleryImage2} className='home-container__gallery-section__image' />
							</Row>
						</Col>
						<Col xs={24} sm={20} md={8} lg={8} xl={8}>
							<Row>
								<img src={galleryImage3} className='home-container__gallery-section__image' />
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Sekcja z formą kontaktową */}
			<Row className='home-container__contact-form-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row className='home-container__contact-form-section__title'>
						Kontakt
					</Row>
					<Row className='home-container__contact-form-section__description'>
						Pozostańmy w kontakcie
					</Row>
					<Row className='home-container__contact-form-section__label'>
						EMAIL
					</Row>
					<Row className='home-container__contact-form-section__input-section'>
						<Input className='home-container__contact-form-section__input-section__input' />
					</Row>
					<Row className='home-container__contact-form-section__button-section'>
						<Button className='home-container__contact-form-section__button-section__button'>
							Wyślij
						</Button>
					</Row>
				</Col>
			</Row>

			{/* Sekcja z przekierowaniem do szczegółów agencji */}
			<Row className='home-container__about-agency-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row className='home-container__about-agency-section__title'>
						O NAS
					</Row>
					<Row className='home-container__about-agency-section__subtitle'>
						Jak działamy
					</Row>
					<Row className='home-container__about-agency-section__button-section'>
						<Button className='home-container__about-agency-section__button-section__button'>
							Więcej
						</Button>
					</Row>
				</Col>
			</Row>

			{/* Stopka strony */}
			<Row className='home-container__footer-section' justify='center'>
				<Col xs={22} sm={22} md={22} lg={22} xl={20}>
					<Row>
						<Col xs={24} sm={20} md={6} lg={6} xl={6}>
							<Row className='home-container__footer-section__title'>
								KONTAKT
							</Row>
							<Row className='home-container__footer-section__element'>
								Telefon:  +48 668 913 929
							</Row>
							<Row className='home-container__footer-section__element'>
								Email: info@podobno.pl
							</Row>
						</Col>
						<Col xs={24} sm={20} md={6} lg={6} xl={6}>
							<Row className='home-container__footer-section__title'>
								ADRES
							</Row>
							<Row className='home-container__footer-section__element'>
								pl. Solny 14/3
							</Row>
							<Row className='home-container__footer-section__element'>
								50-062 Wrocław
							</Row>
						</Col>
						<Col xs={24} sm={20} md={6} lg={6} xl={6}>

						</Col>
						<Col xs={24} sm={20} md={6} lg={6} xl={6}>
							<Row>
								<img src={bigLogoImage} className='home-container__footer-section__image' />
							</Row>
						</Col>

					</Row>
				</Col>

			</Row>


		</>
	);
};

export default HomeContainer;
