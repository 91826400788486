import React from 'react';
import { Form, Input, Button } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../../i18n';
import Role from 'App/types/role';
import { formRules } from 'App/common/formRules/formRules';

interface RegisterFormProps extends FormProps {
	loading: boolean;
}

const RegisterForm = (props: RegisterFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;
	const { firstName, lastName, email, password, confirmPassword } = formRules.user;

	return (
		<Form {...rest}>
			<Form.Item
				name='firstName'
				messageVariables={{ arg: t('models:User.Labels.FirstName') }}
				rules={firstName()}
			>
				<Input placeholder={t('models:User.Placeholders.FirstName')} />
			</Form.Item>
			<Form.Item name='lastName' messageVariables={{ arg: t('models:User.Labels.LastName') }} rules={lastName()}>
				<Input placeholder={t('models:User.Placeholders.LastName')} />
			</Form.Item>
			<Form.Item name='email' messageVariables={{ arg: t('models:User.Labels.Email') }} rules={email()}>
				<Input placeholder={t('models:User.Placeholders.Email')} />
			</Form.Item>
			<Form.Item name='password' messageVariables={{ arg: t('models:User.Labels.Password') }} rules={password()}>
				<Input type='password' placeholder={t('models:User.Placeholders.Password')} />
			</Form.Item>
			<Form.Item
				name='confirmPassword'
				messageVariables={{ arg: t('models:User.Labels.ConfirmPassword') }}
				rules={confirmPassword('password')}
			>
				<Input type='password' placeholder={t('models:User.Placeholders.ConfirmPassword')} />
			</Form.Item>
			<Form.Item name='language' initialValue={i18n.language} hidden>
				<Input hidden />
			</Form.Item>
			<Form.Item name='roles' initialValue={[Role.User]} hidden>
				<Input hidden />
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.SignUp')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RegisterForm;
