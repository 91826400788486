import React from 'react';
import { Route } from 'react-router-dom';
import LoginContainer from './views/login/LoginContainer';
import RegisterContainer from './views/register/RegisterContainer';

const AuthPages = [
	<Route key='/sign-in' exact path='/sign-in' component={LoginContainer} />,
	<Route key='/sign-up' exact path='/sign-up' component={RegisterContainer} />,
];

export default AuthPages;
