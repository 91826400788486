import agent from 'App/api/agent/agent';
import { AppThunk } from '../store';
import {
	getCurrentMessagesStart,
	getCurrentMessagesSuccess,
	getCurrentMessagesFailure,
} from './maintenanceMessages.global.slice';

export const getCurrentMessages = (): AppThunk => async (dispatch) => {
	dispatch(getCurrentMessagesStart());
	agent.MaintenanceMessages.getCurrentMessages()
		.then((response) => dispatch(getCurrentMessagesSuccess(response)))
		.catch(() => dispatch(getCurrentMessagesFailure()));
};
