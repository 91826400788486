import { LogForGetLogsFilesResponse } from 'App/api/endpoints/logs/responses';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface AdminLogsState {
	status: {
		getLogsFiles: StatusType;
		getLogsFileContent: StatusType;
		downloadLogsFile: StatusType;
		downloadAllLogsFiles: StatusType;
	};
	error: string[];
	logs: LogForGetLogsFilesResponse[];
	logContent: string[];
}

export const adminLogsInitialState: AdminLogsState = {
	status: {
		getLogsFiles: Initial,
		getLogsFileContent: Initial,
		downloadLogsFile: Initial,
		downloadAllLogsFiles: Initial,
	},
	error: null,
	logs: [],
	logContent: [],
};
