import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import CreateMessageModal from '../createMessage/modals/CreateMessageModal';
import { MessageFormValues } from '../forms/utils/messageFormValues';
import { CreateMessageRequest, UpdateMessageRequest } from 'App/api/endpoints/maintenanceMessages/requests';
import { MessageForGetMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses/getMessagesResponse';
import { RootState } from 'App/globalState/root.reducer';
import { createMessage, deleteMessage, getMessages, updateMessage } from '../../state/maintenanceMessages.thunk';
import { cleanUpMessagesTable } from '../../state/maintenanceMessages.slice';
import UpdateMessageModal from '../updateMessage/modals/UpdateMessageModal';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import MessagesTable from './components/messagesTable/MessagesTable';

const { Loading } = StatusType;
const { defaultPaginationValues } = paginationUtils;

const { getLocalDateTime } = dateTimeUtils;

const MessagesTableContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const messages = useSelector((state: RootState) => state.pages.admin.messages.messages);
	const messagesStatus = useSelector((state: RootState) => state.pages.admin.messages.status);
	const getMessagesParams = useSelector((state: RootState) => state.pages.admin.messages.getMessagesParams);

	const [messagesType, setMessagesType] = useState<'All' | 'Upcoming' | 'Current'>('All');
	const [selectedMessage, setSelectedMessage] = useState<MessageForGetMessagesResponse>(null);
	const [createMessageModalVisibility, setCreateMessageModalVisibility] = useState<boolean>(false);
	const [updateMessageModalVisibility, setUpdateMessageModalVisibility] = useState<boolean>(false);

	const tableLoading =
		messagesStatus.getMessages === Loading ||
		messagesStatus.createMessage === Loading ||
		messagesStatus.updateMessage === Loading ||
		messagesStatus.deleteMessage === Loading;

	useEffect(() => {
		dispatch(getMessages({ ...getMessagesParams, messagesType: messagesType }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, messagesType]);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		dispatch(
			getMessages({
				...defaultPaginationValues,
				messagesType: 'All',
				orderBy: [
					{
						field: 'startDate',
						direction: 'Ascending',
					},
				],
			})
		);

		return () => {
			dispatch(cleanUpMessagesTable());
		};
	}, [dispatch]);

	const onCreateMessageModalOk = (values: MessageFormValues) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.MaintenanceMessagesPages.CreateMessageSuccess'),
			});
			setCreateMessageModalVisibility(false);
			dispatch(getMessages(getMessagesParams));
		};

		let request: CreateMessageRequest = {
			description: values.description,
			startDateInUtc: moment.utc(values.startDate).toJSON(),
			endDateInUtc: moment.utc(values.endDate).toJSON(),
		};
		dispatch(createMessage(request, onSuccess));
	};

	const onUpdateMessageModalOk = (values: MessageFormValues) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.MaintenanceMessagesPages.UpdateMessageSuccess'),
			});
			setUpdateMessageModalVisibility(false);
			dispatch(getMessages(getMessagesParams));
		};

		let request: UpdateMessageRequest = {
			description: values.description,
			startDateInUtc: moment.utc(values.startDate).toJSON(),
			endDateInUtc: moment.utc(values.endDate).toJSON(),
		};
		dispatch(updateMessage(selectedMessage?.id, request, onSuccess));
	};

	const onUpdateMessageClick = (message: MessageForGetMessagesResponse) => {
		setSelectedMessage(message);
		setUpdateMessageModalVisibility(true);
	};

	const onDeleteMessage = (message: MessageForGetMessagesResponse) => {
		Modal.confirm({
			title: `${t('AdminPages.MaintenanceMessagesPages.ConfirmMessageDeletionTitle')}`,
			icon: <ExclamationCircleOutlined />,
			content: t('common:Warnings.ActionWillBeIrreversible'),
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.MaintenanceMessagesPages.SuccessDeleteDescription'),
					});
					dispatch(getMessages(getMessagesParams));
				};
				dispatch(deleteMessage(message.id, onSucces));
			},
		});
	};

	return (
		<>
			<CreateMessageModal
				loading={messagesStatus.createMessage === Loading}
				onCancel={() => setCreateMessageModalVisibility(false)}
				onOk={onCreateMessageModalOk}
				showModal={createMessageModalVisibility}
			/>
			<UpdateMessageModal
				initialValues={{
					description: selectedMessage?.description,
					startDate: getLocalDateTime(selectedMessage?.startDateInUtc),
					endDate: getLocalDateTime(selectedMessage?.endDateInUtc),
				}}
				loading={messagesStatus.updateMessage === Loading}
				onCancel={() => setUpdateMessageModalVisibility(false)}
				onOk={onUpdateMessageModalOk}
				showModal={updateMessageModalVisibility}
			/>
			<Row>
				<Col span={24}>
					<MessagesTable
						setCreateMessageModalVisibility={setCreateMessageModalVisibility}
						dataSource={messages}
						onDeleteMessageClick={onDeleteMessage}
						onUpdateMessageClick={onUpdateMessageClick}
						getMessages={getMessages}
						setMessagesType={setMessagesType}
						loading={tableLoading}
						requestPagination={getMessagesParams}
					/>
				</Col>
			</Row>
		</>
	);
};

export default MessagesTableContainer;
