import React from 'react';
import { useSelector } from 'react-redux';
import Role from 'App/types/role';
import AdminNavbar from '../components/Navbar/AdminNavbar';
import UserNavbar from '../components/Navbar/UserNavbar';
import NotLoggedInNavbar from '../components/Navbar/NotLoggedInNavbar';
import { RootState } from 'App/globalState/root.reducer';

const NavbarContainer = () => {
	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);

	if (accountDetails) {
		if (accountDetails.roles.includes(Role.Admin)) {
			return <AdminNavbar accountDetails={accountDetails} />;
		} else if (accountDetails.roles.includes(Role.User)) {
			return <UserNavbar accountDetails={accountDetails} />;
		}
	} else {
		return <NotLoggedInNavbar />;
	}
};

export default NavbarContainer;
