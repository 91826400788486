import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';

import { PageHeader, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';

import RegisterForm from './forms/registerForm/RegisterForm';
import { RegisterRequest } from 'App/api/endpoints/auth/requests';
import StatusType from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { register } from 'App/globalState/auth/auth.global.thunk';

interface RegisterContainerProps extends RouteChildrenProps {
	name?: string;
}

const RegisterContainer = ({ history }: RegisterContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const { t } = useTranslation('page');

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.global.auth.status.register);

	const registerHandler: FinishFormType = (values: RegisterRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/sign-in');
			notification.success({
				message: t('common:Success.Success'),
				description: t('AuthPages.RegisterSuccess'),
			});
		};
		dispatch(register(values, handleSuccess));
	};

	return (
		<div className='register__container'>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<br />

					<PageHeader title={t('AuthPages.RegisterPageHeaderTitle')} />
					<RegisterForm
						preserve
						className='register-form'
						name='registerForm'
						size='large'
						onFinish={registerHandler}
						autoComplete='off'
						loading={status === StatusType.Loading}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default RegisterContainer;
