import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, notification, PageHeader, Popconfirm, Result, Row } from 'antd';
import { ChangePasswordRequest } from 'App/api/endpoints/account/requests';
import { UpdateAccountDetailsRequest } from 'App/api/endpoints/account/requests/updateAccountDetailsRequest';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { getAccountDetails } from 'App/globalState/account/account.global.thunk';
import { logout } from 'App/globalState/auth/auth.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateAccountDetails, deleteAccount, changePassword } from '../../state/account.thunk';
import ChangePasswordModal from '../changePassword/modals/changePasswordModal/ChangePasswordModal';
import EditProfileForm from './forms/EditProfileForm';
import './styles/EditProfileContainer.less';

const { Loading } = StatusType;

const EditProfileContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['page', 'common']);
	const history = useHistory();

	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);
	const updateAccountStatus = useSelector((state: RootState) => state.pages.account.status.updateAccountDetails);
	const getAccountDetailsStatus = useSelector((state: RootState) => state.global.account.status.getAccountDetails);
	const deleteAccountStatus = useSelector((state: RootState) => state.pages.account.status.deleteAccount);
	const changePasswordStatus = useSelector((state: RootState) => state.pages.account.status.changePassword);

	const [changePasswordModalVisiblity, setChangePasswordModalVisiblity] = useState<boolean>(false);

	useEffect(() => {
		if (!accountDetails) dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);

	const onFinish = (values: UpdateAccountDetailsRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.UpdateAccountDetailsSuccess'),
			});
		};
		dispatch(updateAccountDetails(values, onSuccess));
	};

	const handleDeleteAccount = () => {
		const onDeleteAccountSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.DeleteAccountSuccess'),
			});

			let onLogoutSuccess = () => {
				history.push('/');
			};

			dispatch(logout(onLogoutSuccess));
		};
		dispatch(
			deleteAccount(
				{ ...(accountDetails as UpdateAccountDetailsRequest), isDeleted: true },
				onDeleteAccountSuccess
			)
		);
	};

	const handleChangePassword = () => {
		setChangePasswordModalVisiblity(true);
	};

	const handleChangePasswordModalCancel = () => {
		setChangePasswordModalVisiblity(false);
	};

	const handleChangePasswordModalSave = (values: ChangePasswordRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.SuccessChangePasswordDescription'),
			});

			setChangePasswordModalVisiblity(false);
		};
		dispatch(changePassword(values, onSuccess));
	};

	if (accountDetails) {
		return (
			<>
				<ChangePasswordModal
					loading={changePasswordStatus === Loading}
					onCancel={() => handleChangePasswordModalCancel()}
					onSave={(values: ChangePasswordRequest) => handleChangePasswordModalSave(values)}
					showModal={changePasswordModalVisiblity}
				/>
				<Row justify='center'>
					<Col span={24}>
						<Row justify='center'>
							<Col>
								<PageHeader className='p-3' title={t('AccountPages.EditProfilePageTitle')} />
							</Col>
						</Row>
						<Row justify='center'>
							<Col xs={24} md={20} lg={10} xl={8} xxl={6}>
								<EditProfileForm
									name='editProfileForm'
									size='middle'
									onFinish={onFinish}
									initialValues={accountDetails}
									loading={updateAccountStatus === Loading}
								/>
							</Col>
						</Row>
						<Divider />
					</Col>
				</Row>
				<Row justify='center'>
					<Col xs={24} md={20} lg={10} xl={8} xxl={6}>
						<Row justify='space-around'>
							<Col className='justify-content-center'>
								<Popconfirm
									title={t('common:Warnings.ActionWillBeIrreversible')}
									icon={<ExclamationCircleOutlined className='popconfirm__icon' />}
									onConfirm={handleDeleteAccount}
								>
									<Button loading={deleteAccountStatus === Loading} danger>
										{t('AccountPages.DeleteAccount')}
									</Button>
								</Popconfirm>
							</Col>
							<Col className='justify-content-center'>
								<Button loading={changePasswordStatus === Loading} onClick={handleChangePassword}>
									{t('AccountPages.ChangePassword')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	} else {
		if (getAccountDetailsStatus === Loading) {
			return <LoadingScreen container='fill' />;
		} else {
			return (
				<Result
					status='warning'
					title={t('common:Errors.AnErrorOccured')}
					extra={
						<Button type='primary' onClick={() => history.push('/')}>
							{t('common:Actions.BackToHome')}
						</Button>
					}
				/>
			);
		}
	}
};

export default EditProfileContainer;
