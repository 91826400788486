import React from 'react';

import { Layout } from 'antd';
import { useMediaQuery } from 'react-responsive';

import AdminUsersPages from '../usersPages/AdminUsersPages';
import AdminLogsPages from '../logsPages/AdminLogsPages';
import AdminMessagesPages from '../maintenanceMessagesPages/AdminMessagesPages';
import AdminNavbarContainer from '../adminNavbar/AdminNavbarContainer';

import './styles/AdminLayoutContainer.less';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';

const AdminLayoutContainer = () => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.xs.max });

	const Content = (
		<>
			<AdminUsersPages />
			<AdminLogsPages />
			<AdminMessagesPages />
		</>
	);
	return (
		<Layout className='admin-page'>
			<Layout.Sider
				className='admin-page__sidebar bg-site'
				collapsible
				defaultCollapsed={isMobile}
				collapsedWidth='40px'
				width={256}
			>
				<AdminNavbarContainer />
			</Layout.Sider>
			<Layout.Content className='pt-3 content-layout'>{Content}</Layout.Content>
		</Layout>
	);
};

export default AdminLayoutContainer;
