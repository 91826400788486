import { MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Mobile } from '../Responsive/Mobile';
import { Default } from '../Responsive/Default';
import AccountSubMenu from './AccountSubMenu';
import { useTranslation } from 'react-i18next';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import HomeMenuItem from './HomeMenuItem';

interface AdminNavbarProps {
	accountDetails: GetAccountDetailsResponse;
}

const AdminNavbar = ({ accountDetails }: AdminNavbarProps) => {
	const location = useLocation();
	const { t } = useTranslation('page');

	const adminUsersMenuItem = (
		<Menu.Item key='/admin/users'>
			<Link to='/admin/users'>{t('Navbar.Admin')}</Link>
		</Menu.Item>
	);
	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<Menu.SubMenu
								key='admin-mobile'
								className='float-right'
								icon={<MenuOutlined className='mr-0' />}
							>
								{adminUsersMenuItem}
								<Menu.Divider />
								<AccountSubMenu
									key='accountsubmenu-mobile'
									isMobile={true}
									accountDetails={accountDetails}
								/>
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>

			<Default>
				<Row justify='space-between'>
					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
							{adminUsersMenuItem}
						</Menu>
					</Col>

					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<AccountSubMenu
								key='accountsubmenu-default'
								isMobile={false}
								accountDetails={accountDetails}
							/>
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default AdminNavbar;
