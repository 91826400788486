import {
	getLogsFilesStart,
	getLogsFilesSuccess,
	getLogsFilesFailure,
	getLogsFileContentStart,
	getLogsFileContentSuccess,
	getLogsFileContentFailure,
	downloadLogsFileStart,
	downloadLogsFileSuccess,
	downloadLogsFileFailure,
	downloadAllLogsFilesStart,
	downloadAllLogsFilesSuccess,
	downloadAllLogsFilesFailure,
	cleanUpLogStatusStart,
} from './logs.slice';
import agent from 'App/api/agent/agent';
import { AppThunk } from 'App/globalState/store';

export const getLogsFiles = (): AppThunk => async (dispatch) => {
	dispatch(getLogsFilesStart());
	agent.Logs.getLogsFiles()
		.then((response) => dispatch(getLogsFilesSuccess(response)))
		.catch(() => dispatch(getLogsFilesFailure()));
};

export const getLogsFileContent =
	(logName: string): AppThunk =>
	async (dispatch) => {
		dispatch(getLogsFileContentStart());
		agent.Logs.getLogsFileContent(logName)
			.then((response) => dispatch(getLogsFileContentSuccess(response)))
			.catch(() => dispatch(getLogsFileContentFailure()));
	};

export const downloadLogsFile =
	(logName: string): AppThunk =>
	async (dispatch) => {
		dispatch(downloadLogsFileStart());
		agent.Logs.downloadLogsFile(logName)
			.then(() => dispatch(downloadLogsFileSuccess()))
			.catch(() => dispatch(downloadLogsFileFailure()));
	};

export const downloadAllLogsFiles =
	(zipName: string): AppThunk =>
	async (dispatch) => {
		dispatch(downloadAllLogsFilesStart());
		agent.Logs.downloadAllLogsFiles(zipName)
			.then(() => dispatch(downloadAllLogsFilesSuccess()))
			.catch(() => dispatch(downloadAllLogsFilesFailure()));
	};

export const cleanUpLogStatus = (): AppThunk => async (dispatch) => {
	dispatch(cleanUpLogStatusStart());
};
