import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';

import { PageHeader, Row, Col, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';

import LoginForm from './forms/loginForm/LoginForm';
import { LoginRequest } from 'App/api/endpoints/auth/requests';
import StatusType from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { cleanUpAuthStatus, login } from 'App/globalState/auth/auth.global.thunk';
import { RootState } from 'App/globalState/root.reducer';

interface LoginContainerProps extends RouteChildrenProps {
	name?: string;
}

const LoginContainer = ({ history }: LoginContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const { t } = useTranslation('page');

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.global.auth.status.login);

	useEffect(() => {
		return () => {
			dispatch(cleanUpAuthStatus());
		};
	}, [dispatch]);

	const formInitialValues = {
		email: 'admin@test.com',
		password: 'Admin123!',
	};

	const signInHandler: FinishFormType = (values: LoginRequest) => {
		const onSuccess = () => {
			let returnUrl = localStorage.getItem('returnUrl');
			localStorage.removeItem('returnUrl');
			let path = returnUrl ? returnUrl : '/';
			history.push(path);
		};
		const onFailure = (detailedErrorCode: string) => {
			notification['error']({
				message: i18n.t('common:Errors.Error'),
				description: i18n.t(`detailedErrors:${detailedErrorCode}.DescriptionFormatter`),
			});
		};

		dispatch(login(values, onSuccess, onFailure));
	};

	return (
		<div className='login-container'>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<PageHeader className='p-3' title={t('AuthPages.LoginPageHeaderTitle')} />
					<LoginForm
						name='loginForm'
						size='large'
						onFinish={signInHandler}
						initialValues={formInitialValues}
						autoComplete='off'
						loading={status === StatusType.Loading}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default LoginContainer;
