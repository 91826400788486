import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface AuthState {
	status: {
		login: StatusType;
		logout: StatusType;
		register: StatusType;
		refreshToken: StatusType;
	};
	tokens: {
		accessToken: string;
		refreshToken: string;
	};
	flags: {
		appStarting: boolean;
		login: boolean;
	};
}

export const authInitialState: AuthState = {
	status: {
		login: Initial,
		logout: Initial,
		register: Initial,
		refreshToken: Initial,
	},
	tokens: {
		accessToken: null,
		refreshToken: null,
	},
	flags: {
		appStarting: true,
		login: false,
	},
};
