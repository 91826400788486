import store from 'App/globalState/store';
import axios from 'axios';
import { errorHandler } from '../errorHandling/errorHandler';

export const baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(
	(config) => {
		// token interceptor goes here
		const token = store.getState().global?.auth?.tokens?.accessToken;
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(null, errorHandler);
axios.defaults.withCredentials = true;
