import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdminLogsState, adminLogsInitialState } from './logs.state';
import { GetLogsFilesResponse } from 'App/api/endpoints/logs/responses';
import { StatusType } from 'App/types/requestStatus';

const { Failed, Loading, Success } = StatusType;

export const adminLogsSlice = createSlice({
	name: 'admin-logs',
	initialState: adminLogsInitialState,
	reducers: {
		getLogsFilesStart: (state: AdminLogsState) => {
			state.status.getLogsFiles = Loading;
			state.logs = adminLogsInitialState.logs;
		},
		getLogsFilesSuccess(state: AdminLogsState, action: PayloadAction<GetLogsFilesResponse>) {
			state.status.getLogsFiles = Success;
			state.logs = action.payload.logs;
		},
		getLogsFilesFailure(state: AdminLogsState) {
			state.status.getLogsFiles = Failed;
		},

		getLogsFileContentStart: (state: AdminLogsState) => {
			state.status.getLogsFileContent = Loading;
			state.logContent = adminLogsInitialState.logContent;
		},
		getLogsFileContentSuccess(state: AdminLogsState, action: PayloadAction<string[]>) {
			state.status.getLogsFileContent = Success;
			state.logContent = action.payload;
		},
		getLogsFileContentFailure(state: AdminLogsState) {
			state.status.getLogsFileContent = Failed;
		},

		downloadLogsFileStart: (state: AdminLogsState) => {
			state.status.downloadLogsFile = Loading;
		},
		downloadLogsFileSuccess(state: AdminLogsState) {
			state.status.downloadLogsFile = Success;
		},
		downloadLogsFileFailure(state: AdminLogsState) {
			state.status.downloadLogsFile = Failed;
		},

		downloadAllLogsFilesStart: (state: AdminLogsState) => {
			state.status.downloadAllLogsFiles = Loading;
		},
		downloadAllLogsFilesSuccess(state: AdminLogsState) {
			state.status.downloadAllLogsFiles = Success;
		},
		downloadAllLogsFilesFailure(state: AdminLogsState) {
			state.status.downloadAllLogsFiles = Failed;
		},

		cleanUpLogStatusStart: (state: AdminLogsState) => {
			state.status = adminLogsInitialState.status;
		},
	},
});

export default adminLogsSlice;

export const {
	getLogsFilesStart,
	getLogsFilesSuccess,
	getLogsFilesFailure,

	getLogsFileContentStart,
	getLogsFileContentSuccess,
	getLogsFileContentFailure,

	downloadLogsFileStart,
	downloadLogsFileSuccess,
	downloadLogsFileFailure,

	downloadAllLogsFilesStart,
	downloadAllLogsFilesSuccess,
	downloadAllLogsFilesFailure,

	cleanUpLogStatusStart,
} = adminLogsSlice.actions;
