import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { adminMaintenanceMessagesInitialState, AdminMaintenanceMessagesState } from './maintenanceMessages.state';
import { StatusType } from 'App/types/requestStatus';
import {
	GetMessagesResponse,
	GetMessageResponse,
	UpdateMessageResponse,
} from 'App/api/endpoints/maintenanceMessages/responses';
import { GetMessagesRequest } from 'App/api/endpoints/maintenanceMessages/requests';

const { Failed, Loading, Success } = StatusType;

export const adminMaintenanceMessagesSlice = createSlice({
	name: 'admin-maintenace-messages',
	initialState: adminMaintenanceMessagesInitialState,
	reducers: {
		getMessagesStart: (state: AdminMaintenanceMessagesState) => {
			state.status.getMessages = Loading;
			state.messages = adminMaintenanceMessagesInitialState.messages;
		},
		getMessagesSuccess(state: AdminMaintenanceMessagesState, action: PayloadAction<GetMessagesResponse>) {
			state.status.getMessages = Success;
			const { messages, ...pagination } = action.payload;
			state.messages = action.payload.messages;
			state.getMessagesParams = pagination;
		},
		getMessagesFailure(state: AdminMaintenanceMessagesState) {
			state.status.getMessages = Failed;
		},

		getMessageStart: (state: AdminMaintenanceMessagesState) => {
			state.status.getMessage = Loading;
			state.selectedMessage = adminMaintenanceMessagesInitialState.selectedMessage;
		},
		getMessageSuccess: (state: AdminMaintenanceMessagesState, action: PayloadAction<GetMessageResponse>) => {
			state.status.getMessage = Success;
			state.selectedMessage = action.payload;
		},
		getMessageFailure: (state: AdminMaintenanceMessagesState) => {
			state.status.getMessage = Failed;
		},

		deleteMessageStart: (state: AdminMaintenanceMessagesState) => {
			state.status.deleteMessage = Loading;
		},
		deleteMessageSuccess: (state: AdminMaintenanceMessagesState, action: PayloadAction<number>) => {
			state.status.deleteMessage = Success;
			state.messages = state.messages.filter((m) => m.id !== action.payload);
		},
		deleteMessageFailure: (state: AdminMaintenanceMessagesState) => {
			state.status.deleteMessage = Failed;
		},

		createMessageStart: (state: AdminMaintenanceMessagesState) => {
			state.status.createMessage = Loading;
		},
		createMessageSuccess: (state: AdminMaintenanceMessagesState) => {
			state.status.createMessage = Success;
		},
		createMessageFailure: (state: AdminMaintenanceMessagesState) => {
			state.status.createMessage = Failed;
		},

		updateMessageStart: (state: AdminMaintenanceMessagesState) => {
			state.status.updateMessage = Loading;
		},
		updateMessageSuccess: (state: AdminMaintenanceMessagesState, action: PayloadAction<UpdateMessageResponse>) => {
			state.status.updateMessage = Success;
			const message = state.messages.find((u) => u.id === action.payload.id);

			if (message) {
				const { startDateInUtc: startDate, endDateInUtc: endDate, description } = action.payload;

				message.startDateInUtc = startDate;
				message.endDateInUtc = endDate;
				message.description = description;
			}
		},
		updateMessageFailure: (state: AdminMaintenanceMessagesState) => {
			state.status.updateMessage = Failed;
		},

		updateGetMessagesParams: (state: AdminMaintenanceMessagesState, action: PayloadAction<GetMessagesRequest>) => {
			state.getMessagesParams = action.payload;
		},

		cleanUpMessageStatus: (state: AdminMaintenanceMessagesState) => {
			state.status = adminMaintenanceMessagesInitialState.status;
		},

		cleanUpMessagesTable: (state: AdminMaintenanceMessagesState) => {
			state.getMessagesParams = adminMaintenanceMessagesInitialState.getMessagesParams;
			state.messages = adminMaintenanceMessagesInitialState.messages;
			state.status.getMessages = adminMaintenanceMessagesInitialState.status.getMessages;
		},

		cleanUpSelectedMessage: (state: AdminMaintenanceMessagesState) => {
			state.selectedMessage = adminMaintenanceMessagesInitialState.selectedMessage;
		},
	},
});

export default adminMaintenanceMessagesSlice;

export const {
	getMessagesStart,
	getMessagesSuccess,
	getMessagesFailure,

	getMessageStart,
	getMessageSuccess,
	getMessageFailure,

	deleteMessageStart,
	deleteMessageSuccess,
	deleteMessageFailure,

	createMessageStart,
	createMessageSuccess,
	createMessageFailure,

	updateMessageStart,
	updateMessageSuccess,
	updateMessageFailure,

	updateGetMessagesParams,

	cleanUpMessageStatus,
	cleanUpSelectedMessage,
	cleanUpMessagesTable,
} = adminMaintenanceMessagesSlice.actions;
