import React from 'react';
import { Form, Input, Button, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { MessageFormValues } from './utils/messageFormValues';
import { formRules } from 'App/common/formRules/formRules';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';

interface MaintenanceMessageFormProps {
	onFinish: (values: MessageFormValues) => void;
	initialValues?: MessageFormValues;
	loading: boolean;
}

const MessageForm = ({ initialValues, loading, onFinish }: MaintenanceMessageFormProps) => {
	const { t } = useTranslation(['form', 'common']);
	const { startDate, endDate, description } = formRules.maintenanceMessages;

	return (
		<Form layout='vertical' className='px-3' initialValues={initialValues} onFinish={onFinish}>
			<Form.Item
				label={t('models:MaintenanceMessage.Labels.StartDate')}
				messageVariables={{ arg: t('models:MaintenanceMessage.Labels.StartDate') }}
				name='startDate'
				rules={startDate()}
			>
				<DatePicker
					className='w-100'
					showTime={{ format: dateTimeUtils.formats.HourMinute, showSecond: false }}
					placeholder={t('models:MaintenanceMessage.Placeholders.StartDate')}
					format={dateTimeUtils.formats.YearMonthDayHourMinute}
				/>
			</Form.Item>
			<Form.Item
				label={t('models:MaintenanceMessage.Labels.EndDate')}
				messageVariables={{ arg: t('models:MaintenanceMessage.Labels.EndDate') }}
				name='endDate'
				rules={endDate()}
			>
				<DatePicker
					className='w-100'
					showTime={{ format: dateTimeUtils.formats.HourMinute, showSecond: false }}
					placeholder={t('models:MaintenanceMessage.Placeholders.EndDate')}
					format={dateTimeUtils.formats.YearMonthDayHourMinute}
				/>
			</Form.Item>
			<Form.Item
				label={t('models:MaintenanceMessage.Labels.Description')}
				messageVariables={{ arg: t('models:MaintenanceMessage.Labels.Description') }}
				name='description'
				rules={description()}
			>
				<Input.TextArea placeholder={t('models:MaintenanceMessage.Placeholders.Description')} />
			</Form.Item>

			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Create')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default MessageForm;
